<template>
  <div>
    <a-row type="flex" justify="center" class="table_head">
      <a-col :span="7">
        <a-button class="editable-add-btn" type="primary" @click="handleAdd"
          >Nouveau
        </a-button>
      </a-col>
      <a-col :span="6">
        <search-component
          v-on:change="searchTypeAttestation($event)"
        ></search-component>
      </a-col>
    </a-row>

    <a-row type="flex" justify="center">
      <a-col :span="13">
        <a-spin :spinning="processing">
          <a-table
            v-if="dataSource.length"
            :dataSource="dataSource"
            :columns="columns"
            :rowKey="
              record => {
                return record.id;
              }
            "
          >
            <template slot="row_index" slot-scope="text, record, index">
              <p>{{ index + 1 }}</p>
            </template>
            <template slot="nom" slot-scope="text, record">
              <editable-cell
                :text="text"
                @change="onCellChange(record, 'name', $event)"
              />
            </template>
            <template slot="operation" slot-scope="text, record">
              <a-popconfirm
                v-if="dataSource.length"
                :title="SUPPRIMER_LIGNE"
                @confirm="() => onDelete(record)"
              >
                <a-tooltip>
                  <a-icon
                    type="delete"
                    class="action_icons"
                    theme="twoTone"
                    twoToneColor="#eb2f96"
                  />
                  <template slot="title">Suppression</template>
                </a-tooltip>
              </a-popconfirm>
            </template>
            <template slot="etat" slot-scope="text, record">
              <a-icon
                type="check-circle"
                theme="twoTone"
                twoToneColor="#52c41a"
              />
            </template>
          </a-table>
          <EmptyResult v-else />
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>
<script>
  import EditableCell from '@/components/common/EditableCell'
  import {mapActions, mapState} from "vuex";
  import SearchComponent from "@/components/common/Search";
  import EmptyResult from "@/components/common/EmptyResult";
  import constants from "@/const/const";
  import debounce from "lodash-es/debounce";


  const columns = [
    {
      title: "N°",
      scopedSlots: {customRender: "row_index"}
    },
    {
      title: "Nom Attestation",
      dataIndex: "nom_fr",
      scopedSlots: {customRender: "nom"}
    },
    {
    title: "Action",
    scopedSlots: { customRender: "operation" }
  }
];
export default {
  name: "AttestationsTypeList",
  created() {
    this.fetchData();
  },
  components: {
    EditableCell,
    SearchComponent,
    EmptyResult
  },
  data() {
    this.searchTypeAttestation = debounce(this.searchTypeAttestation, 1000);
    return {
      processing: true,
      columns: columns,
      SUPPRIMER_LIGNE: constants.SUPPRIMER_LIGNE
    };
  },
  computed: {
    ...mapState({
      dataSource: state => state.types_attestations.type_attestations
    })
  },
  methods: {
    fetchData(q = "") {
      this.startProcessing();
      this.getAdditioanlData({q: q})
              .finally(() => this.stopProcessing());
    },
    searchTypeAttestation(q) {
      this.fetchData(q);
    },
    onCellChange(attestation, dataIndex, $event) {
      this.startProcessing();
      this.update({...attestation, 'nom_fr': $event})
              .then(() => this.fetchData())
              .finally(() => this.stopProcessing());
    },
    onDelete(attestation) {
      this.startProcessing();
      this.remove(attestation)
              .then(() => this.fetchData())
              .finally(() => this.stopProcessing());
    },
    handleAdd() {
      this.startProcessing();
      this.add({nom_fr: `Saisissez un type`})
              .then(() => this.fetchData())
              .finally(() => this.stopProcessing());
    },
    startProcessing() {
      this.processing = true;
    },
    stopProcessing() {
      this.processing = false;
    },
    ...mapActions({
      getAdditioanlData: "fetchTypeAttestations",
      update: "updateTypeAttestation",
      remove: "destroyTypeAttestation",
      add: "storeTypeAttestation"
    })
  }
};
</script>
<style scoped>
  .table_head {
    margin-bottom: 5%;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
</style>
