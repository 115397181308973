<template>
  <div>
    <h1 style="margin-left:4%;margin-bottom:4%">Types des attestations</h1>
    <AttestationsList></AttestationsList>
  </div>
</template>

<script>
import TypesAttestationsList from "./TypesAttestationsList";

export default {
  name: "AttestationTypes",
  components: { AttestationsList: TypesAttestationsList }
};
</script>

<style scoped>
</style>